html {
  overflow: hidden;
  height: 100%;
}

body {
  margin: 0;
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  background-color: #1d1e25;
  background-image: url("./images/LeagueLink_Shield_v1.png");
  background-repeat: no-repeat;
  background-position: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
